<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Edit Video Details</h3>
        <button class="btn btn-control close-btn" @click="$emit('close')">
          <img alt="" src="@/assets/icons/icon-close.png" />
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <div class="row mt-3">
            <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError"/>
          </div>
          <div class="row mt-3">
            <div class="col-lg-6">
              <div class="form-group w-100 full-width">
                <label for="titleField">Title</label>
                <input
                  type="text"
                  id="titleField"
                  class="form-control w-100 full-width"
                  placeholder="Enter Title"
                  v-model="formData.title"
                />
              </div>
              <div class="form-group mt-3 w-100 full-width">
                <label for="descField">Description</label>
                <textarea
                  id="descField"
                  cols="30"
                  rows="10"
                  class="form-control full-width"
                  v-model="formData.description"
                ></textarea>
              </div>
              <div class="mt-3 form-group">
                <label>Privacy</label>
                <multiselect
                  v-model="formData.privacy"
                  placeholder="Privacy"
                  class="full-width"
                  :options="privacyTypes"
                  :close-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <label>Preview</label>
              <video :src="formData.videoUrl" class="video-player" controls controlsList="nodownload">
              </video>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <base-button title="Delete" action="secondary" @click-btn="deleteVideo" :loading="ui.deleting" />
        <base-button title="Save Changes" action="secondary-default" @click-btn="updateVideo" :loading="ui.saving" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BaseButton from '../../../../components/BaseButton.vue';
import ValidationErrorsList from "../../../../components/form/ValidationErrorsList";
export default {
  name: "EditTrainingVideo",
  components: { Multiselect, ValidationErrorsList, BaseButton },
  props: {
    video: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      privacyTypes: [],
      formData: {
        id: "",
        privacy: "",
        title: "",
        description: "",
        videoUrl: "",
        videoFile: ""
      },
      ui: {
        loading: false,
        saving: false,
        deleteing: false,
        form: {
          loading: false,
          isSuccess: false,
          isError: false,
          error: '',
          errors: [],
        }
      },
    };
  },
  beforeMount() {
    this.getPrivacyType();

    this.formData.id = this.video.id;
    this.formData.title = this.video.title;
    this.formData.privacy = this.video.privacy;
    this.formData.description = this.video.description;
    this.formData.videoUrl = this.video.videoUrl;
  },
  methods: {
    getPrivacyType() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/training-video/privacy")
        .then((res) => {
          this.privacyTypes = res.data.data;
          this.ui.loading = false;
        })
        .catch(() => {
          this.ui.loading = false;
        });
    },
    updateVideo() {
      this.ui.saving = true;
      let formData = new FormData()
      formData.append('title', this.formData.title);
      formData.append('description', this.formData.description);
      formData.append('privacy', this.formData.privacy);
      this.ui.form.isError = false
      this.ui.form.error = ''
      this.ui.form.errors = []

      this.$http
        .post(`/api/v1/training-video/${this.formData.id}/edit`, formData)
        .then((res) => {
          this.ui.saving = false;
          this.pushAlert("success", res.data.message);
          this.$emit('updated');
        })
        .catch((err) => {
          this.ui.saving = false;
          let validation = this.getErrorsFromResponse(err.response)
          this.ui.form.isError = true
          this.ui.form.error = validation.error
          this.ui.form.errors = validation.errors

          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: this.ui.form.error,
            status: 'error',
            delay: 2000
          });
        });
    },
    deleteVideo() {
      this.ui.deleting = true;
      this.$http
        .delete(`/api/v1/training-video/${this.formData.id}/delete`)
        .then((res) => {
          this.pushAlert("success", res.data.message);
          this.$emit('updated');
        })
        .catch(() => {
          this.ui.deleting = false;
           this.pushAlert(
            "error",
            "Something went wrong. Training video not delete"
          );
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.modal {
  &-footer {
    position: initial !important;
  }
  &-block {
    margin: 100px auto !important;
    height: fit-content !important;

    .modal-body {
      padding-bottom: 40px;
      margin-bottom: 0;

      &-container {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }
    }
  }
}

.video-url {
  max-width: 100%;

  .btn {
    height: 40px;
    max-height: 40px;
    min-width: fit-content;
    margin-left: 12px;
  }

  .form-control {
    height: 40px;
  }
}

canvas {
  width: 100%;
  height: 200px;
}

video {
  z-index: -999999;
  width: 100%;
}
</style>
